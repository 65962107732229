<template>
    <div>
        <Row>
            <span  class="text-main"> 欢迎: </span>
        </Row>
        <Row>
            <Avatar v-if="avatar" shape='square' :src="avatar" />
        </Row>
        <Row>
            <span class="title-main"> {{name}} </span> 
            <!-- <span class="text-assist">( {{uid}} )</span> -->
        </Row>
        <Row>
            <span class="text-main">用户组: {{group_name}}</span>
        </Row>
        <Row>
            <span class="text-link">{{msg}}</span>
        </Row>
        <br />
        <Button type='success' @click='refreshUserInfo' icon='md-refresh'> </Button>
    </div>
</template>


<script>
import {refreshAdminUser} from '@/api/admin';

export default {
    name: "index",
    props: {
    },
    components: {
    },
    data () {
        let self = (window.x = this)
        return {
            name: '',
            avatar: '',
            group_name: '',
            msg: '',
        }
    },
    methods: {
        // 获取用户信息
        getUser(){
            let self = this;
            // console.log( self.$store.state.user)
            let user = self.$store.state.user;
            if (user){
                self.name = user.name;
                self.group_name = user.group_name;
                self.uid = user.uid;
                self.avatar = user.avatar;
            }
            if (!user.route_names ){
                self.msg = '暂无权限 请联系管理员'
            }
        },
        // end
        refreshUserInfo(){
            let self = this;
            refreshAdminUser({}).then(response => {
                if (response.data.code === 0){
                    let user = response.data.data;
                    self.$store.commit('user/loginSuccess', user);
                    localStorage.setItem("user", JSON.stringify(user) );    // 保存token
                    self.$router.go(0); // 刷新本页面
                }else{
                    self.$Message.error(response.data.msg);
                }
            })
        },
        // end 
    },
    mounted () {
        this.getUser();
    },
    watch: {
    },
    computed: {}
}
</script>
<style scoped>
    /* @import "index.css" */
</style>